<template>
  <Breadcrumb :pageTitle="pageTitle" :parkCode="parkCode" />

  <div class="content content--events">
    <EventCard v-for="event in events" :key="event.id" :event="event" />

    <div v-if="events.length === 0">
      <h1>Where did they go?</h1>

      <h1>Oops!</h1>
      <p>
        It looks like our events calendar went on holiday - but don't worry,
        there's always something fun happening<br /><br />
        Come see us at reception to find out what's on during your stay!
      </p>
    </div>
  </div>
</template>

<script>
import EventCard from "@/components/EventCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import moment from "moment";

export default {
  name: "Events",
  props: ["parkCode"],
  components: {
    Breadcrumb,
    EventCard,
  },
  mounted() {
    document.body.classList.add("page--" + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";
  },
  methods: {},
  computed: {
    pageTitle() {
      return "What's on in the Park";
    },
    events() {
      const EVENT_FUTURE_MONTHS = 3; // Total months in advance to show on the page
      moment().format();
      // Add Days prototype
      Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

      // Add Hours prototype
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };

      //typeId = 1 for in park events
      var payload = this.$store.state.parkInfo.activities;

      // filter incoming payload with typeId = 1 and where interval and recurringDay is non-empty.
      // Empty interval and recurringDay are excluded as those have invalid endDate
      var activeEvents = payload.filter(function (el) {
        return el.isActive == 1 && (el.typeId == 1 || el.typeId == 3);
      });
      //console.log("active events");

      function generateEventOccurrences(eventObj) {
        //console.log(`Processing event: ${eventObj.displayName}`);
        if(eventObj.displayName == 'Lolly Guessing') {
          console.log('LOLLYS LOL');
        }
        let occurrences = [];
        let eventStartDate = new Date(eventObj.startDate);
        let eventEndDate = new Date(eventObj.endDate);
        eventEndDate.setHours(23, 59, 59, 999); 
        let recurringDays = eventObj.recurringDay
          .split(";")
          .map((day) => day.trim().toLowerCase());

        //console.log(`Event start date: ${eventStartDate}`);
        //console.log(`Event end date: ${eventEndDate}`);
        //console.log(`Recurring days: ${recurringDays}`);

        const now = new Date();
        const futureCutOffDate = new Date(
          now.getFullYear(),
          now.getMonth() + EVENT_FUTURE_MONTHS,
          now.getDate()
        );

        //console.log(`Current date: ${now}`);
        //console.log(`Future cut-off date: ${futureCutOffDate}`);

        if (eventStartDate > futureCutOffDate) {
          //console.log("Event starts after the future cut-off date. Skipping.");
          return occurrences;
        }

        // Handle cases with no recurring days or special end date
        if (
          recurringDays.length === 1 &&
          recurringDays[0] === "" &&
          eventObj.endDate === "0001-01-01T00:00:00"
        ) {
          //console.log("Event has no recurring days and special end date.");
          occurrences.push(
            createOccurrence(eventObj, eventStartDate, eventStartDate)
          );
          return occurrences;
        }

        // Handle non-recurring events
        if (recurringDays.length === 1 && recurringDays[0] === "") {
          //console.log("Non-recurring event.");
          if (now <= eventEndDate) {
            occurrences.push(
              createOccurrence(eventObj, eventStartDate, eventEndDate)
            );
          }
          return occurrences;
        }

        // Handle recurring events
        //console.log("Recurring event. Generating occurrences...");
        let currentDate = new Date(Math.max(eventStartDate, now));
        const daysOfWeek = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];

        while (currentDate <= futureCutOffDate && currentDate <= eventEndDate) {
          const currentDayName = daysOfWeek[currentDate.getDay()];
          //console.log(`Checking date: ${currentDate}, day: ${currentDayName}`);

          if (
            eventObj.interval.toLowerCase() === "daily" ||
            recurringDays.includes(currentDayName)
          ) {
            //console.log("Match found. Adding occurrence.");
            occurrences.push(
              createOccurrence(
                eventObj,
                new Date(currentDate),
                new Date(currentDate)
              )
            );
          }

          // Move to the next occurrence based on the interval
          switch (eventObj.interval.toLowerCase()) {
            case "daily":
              currentDate.setDate(currentDate.getDate() + 1);
              break;
            case "weekly":
              // If it's the last specified day of the week, move to the next week
              if (
                recurringDays.indexOf(currentDayName) ===
                recurringDays.length - 1
              ) {
                currentDate.setDate(
                  currentDate.getDate() + (7 - recurringDays.length + 1)
                );
              } else {
                currentDate.setDate(currentDate.getDate() + 1);
              }
              break;
            case "monthly":
              currentDate.setMonth(currentDate.getMonth() + 1);
              break;
            default:
              console.warn(`Unsupported interval: ${eventObj.interval}`);
              return occurrences;
          }
        }

        //console.log( `Generated ${occurrences.length} occurrences for this event.`);
        return occurrences;
      }

      function createOccurrence(eventObj, startDate, endDate) {
        return {
          id: eventObj.id,
          displayName: eventObj.displayName,
          startDate: startDate,
          endDate: endDate,
          time: eventObj.time,
          duration: eventObj.duration,
          interval: eventObj.interval,
          recurringDay: eventObj.recurringDay,
          image: eventObj.image,
          description: eventObj.description,
          venueName: eventObj.venueName,
          venueAddress: eventObj.venueAddress,
          displayAfter: eventObj.displayAfter,
          displayBefore: eventObj.displayBefore,
          journeyIds: eventObj.journeyIds,
          cost: eventObj.cost,
        };
      }

      let events = [];
      //console.log(activeEvents);
      activeEvents.forEach((event) => {
        if (
          event.image.includes(".com") ||
          event.image.includes("gdayparks.com")
        ) {
          // If the image is a full URL, replace the domain with a tilde
          event.image = event.image.replace(new RegExp("^https?://[^/]+"), "~");
        }
      });
      activeEvents.forEach((event) => {
        const occurrences = generateEventOccurrences(event);
        console.log( "generated occurances for " + event.displayName, occurrences);
        occurrences.forEach((item) => {
          events.push(item);
        });
      });
      //console.log("HERE", events);
      //return events;

      //console.log(events)
      // Remove events from the past

      let sortedEvents = events.filter((item) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        return new Date(item.endDate) >= today;
      });
      /*let sortedEvents = events.filter((item) => {
        // We want to ensure the event has started (or is still on going )
        if (new Date(item.endDate) >= new Date()) {
          //console.log(new Date(item.endDate), new Date());
          return true;
        } else {
          return false;
        }
      });*/
      //console.log('gottem')
      sortedEvents = sortedEvents.sort((a, b) => {
        return new Date(a.startDate) - new Date(b.startDate);
      });
      //console.log("Events finished");
      return sortedEvents;
    },
  },
};
</script>
