<template>
  <Breadcrumb :pageTitle="pageTitle" :parkCode="this.parkCode" />

  <div class="content content--contact">
    <div class="two-col">
      <div class="contact-item">
        <p>Phone</p>
        <a :href="'tel:' + parkInfo.park.content.phone">{{
          parkInfo.park.content.phone
        }}</a>
      </div>
      <div v-if="parkInfo.park.content.freecall != ''" class="contact-item">
        <p>Freecall</p>
        <a :href="'tel:' + parkInfo.park.content.freecall">{{
          parkInfo.park.content.freecall
        }}</a>
      </div>
    </div>
    <div class="contact-item">
      <p>Address</p>
      <a
        :href="
          'https://www.google.com/maps/search/?api=1&query=' +
          parkInfo.park.content.addressStreet +
          ',' +
          parkInfo.park.content.addressTown
        "
        target="_blank"
        >{{ parkInfo.park.content.addressStreet }},
        {{ parkInfo.park.content.addressTown }}, {{ contactState }},
        {{ parkInfo.park.content.addressPostcode }}</a
      >
    </div>
    <div class="contact-item">
      <p>Email</p>
      <a :href="'mailto:' + parkInfo.park.content.email">{{
        parkInfo.park.content.email
      }}</a>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "Contact",
  props: ["parkCode"],
  components: {
    Breadcrumb,
  },
  mounted() {
    document.body.classList.add("page--" + this.$route.name.toLowerCase());
  },
  unmounted() {
    document.body.classList = "";
  },
  computed: {
    parkInfo() {
      var obj = this.$store.state.parkInfo;
      console.log("gottem here");
      return obj;
    },
    pageTitle() {
      return "Contact";
    },
    contactState() {
      switch (this.$store.state.parkInfo.park.content.addressState) {
        case 121:
          return "ACT";
        case 124:
          return "NSW";
        case 120:
          return "NT";
        case 125:
          return "QLD";
        case 123:
          return "SA";
        case 122:
          return "TAS";
        case 126:
          return "VIC";
        case 127:
          return "WA";
        default:
          return "";
      }
    },
  },
};
</script>
